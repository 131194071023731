<script setup>
    import { useHead } from '@vueuse/head';
    import { metaConfig } from '@/js/config/meta';

    const promo = import.meta.env.VITE_PROMO;
    const link = import.meta.env.VITE_BTN_LINK;

    useHead({
        title: metaConfig.title,
        meta: [
            {
                name: 'description',
                content: metaConfig.description,
            },
            ...metaConfig.metaTags,
        ],
        link: [
            {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: metaConfig.favicon.appleTouchIcon,
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
                href: metaConfig.favicon.favicon32,
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
                href: metaConfig.favicon.favicon16,
            },
            {
                rel: 'manifest',
                href: metaConfig.favicon.manifest,
            },
            {
                rel: 'mask-icon',
                href: metaConfig.favicon.safariPinnedTab,
                color: '#5bbad5',
            },
            {
                rel: 'shortcut icon',
                href: metaConfig.favicon.faviconIco,
            },
        ],
    });

    // Функция показа попап инфо о копировании промокода
    const showPromo = () => {
        const popUp = document.querySelector('.pop-up');
        popUp.classList.add('show');
        setTimeout(() => popUp.classList.remove('show'), 2500);
    };

    // Функция копирования текста в буфер обмена
    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(promo)
            .then(() => {
                console.log('Promo copied: ' + promo);
                showPromo();
            })
            .catch((err) => {
                console.error('Promo do not copied: ', err);
            });
    };
</script>

<template>
    <div class="app">
        <div class="co-brand"></div>
        <div class="promo">
            <div class="pop-up">
                <div class="pop-up__icon"></div>
                <div class="pop-up__text">PROMO CODE HAS BEEN COPIED</div>
            </div>
            <div class="promo-title">promocode</div>
            <div
                class="promo-content"
                @click="copyToClipboard"
            >
                <div class="promo-text">{{ promo }}</div>
                <div class="promo-copy-btn"></div>
            </div>
        </div>
        <a
            :href="link"
            class="btn"
            target="_blank"
            >GO TO THE WEBSITE</a
        >
    </div>
</template>

<style></style>
